<template>
  <div>
    <v-autocomplete
      v-if="styling == 'default'"
      v-model="select"
      :rules="inputRules[rules]"
      type="text"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      :cache-items="false"
      hide-no-data
      hide-selected
      no-filter
      :return-object="true"
      :allow-overflow="false"
      :item-text="formatAddress"
      item-value="osm_id"
      clearable
      dark
      background-color="rgb(255,157,113)"
      color="white"
      rounded
      filled
      :label="label"
      @input="handleInputChange"
    >
      <template v-slot:prepend-inner>
        <v-icon class="white--text mr-2">{{ icon }}</v-icon>
      </template>
      <template v-slot:label>
        <span class="white--text">{{ label }}</span>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-else-if="styling == 'plain'"
      v-model="select"
      :rules="inputRules[rules]"
      type="text"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      :cache-items="false"
      hide-no-data
      hide-selected
      no-filter
      :return-object="true"
      :allow-overflow="false"
      :item-text="formatAddress"
      item-value="osm_id"
      clearable
      :label="label"
      @input="handleInputChange"
    >
      <template v-slot:prepend-inner>
        <v-icon class="mr-2">{{ icon }}</v-icon>
      </template>
      <template v-slot:label>
        <span>{{ label }}</span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import inputRulesMixin from "@/mixins/inputRulesMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { OpenStreetMapProvider } from "leaflet-geosearch";
export default {
  name: "InputGeosearch",
  mixins: [inputRulesMixin, utilitiesMixin],
  props: {
    label: {
      type: String,
      default: "InputField",
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "None",
    },
    styling: {
      type: String,
      default: "default",
    },
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: null,
      errorMessage: null,
      loading: false,
      items: [this.inputValue],
      search: null,
      select: this.inputValue,
      provider: null,
    };
  },
  async created() {
    this.provider = new OpenStreetMapProvider({
      params: {
        countrycodes: "sk",
        addressdetails: 1 /* breakdown into elements */,
        "accept-language": "sk-SK",
        limit: 10 /* default */,
      },
    });
  },
  watch: {
    search(value) {
      value && value !== this.select && this.querySelections(value);
    },
  },
  methods: {
    formatAddress(item) {
      const address = [];

      if (item.raw.address.road) {
        address.push(item.raw.address.road);
      }

      if (item.raw.address.house_number) {
        address.push(item.raw.address.house_number);
      }

      address.push(
        item.raw.address.city_district ||
          item.raw.address.city ||
          item.raw.address.town
      );

      return address.join(" ");
    },
    handleInputChange(item) {
      if (!item || !item.raw) {
        return;
      }

      const location = {
        lat: (item.raw.lat && Number(item.raw.lat)) || undefined,
        lng: (item.raw.lon && Number(item.raw.lon)) || undefined,
      };

      let matchedRegion;
      const region =
        item.raw.address.county ||
        item.raw.address.region ||
        item.raw.address.state;

      const regions = Object.keys(this.$store.state.regions);
      
      const bmatch = this.findBestMatch(region, regions);
      
      if (bmatch.bestMatch && bmatch.bestMatch.rating > 0.6) {
        matchedRegion = regions[bmatch.bestMatchIndex];
      }

      const name = this.formatAddress(item);

      this.select = name;
      this.$emit("input", { name, location, region: matchedRegion || region });
    },
    async querySelections(value) {
      const scope = this;

      scope.loading = true;

      clearTimeout(this._timerId);

      this._timerId = setTimeout(async () => {
        const results = await scope.provider.search({
          query: value,
          country: "sk",
        });

        if (!results || results.length == 0) {
          scope.errorMessage = this.$t("errors.address");
        }

        scope.items = results;

        scope.loading = false;
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.no-placeholder::placeholder {
  color: rgba(0, 0, 0, 0) !important;
}
</style>